import { Icon } from '../generic-icon/Icon';
import { useI18NextContext } from '@components/hooks';
import styled from '@emotion/styled';
import { Theme, Collapse } from '@mui/material';
import { withTheme } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { TestId } from '@components/test-ids';
import { ThemeProps } from '@theme/base';
import Markdown from 'react-markdown';
import { useWindowSize } from 'react-use';
import { mq, breakpoints } from '@styles/breakpoints';
import { ReadMore } from '@styles/common';
import { SkeletonElement } from '../skeleton-loading';
import * as gfm from 'remark-gfm';
import { MarkdownContainer as Container } from './styles/common';
import { LinkComponent } from './TextMarkdown';
import { User } from '@model/iceberg/booking/package/review';
import { User as UserComponent } from '@components/product/reviews/user/User';

/* ***************** *
 *       Types       *
 * ***************** */
export interface TextDestinationFadeProps {
  theme?: Theme;
  testId?: string;
  description: string;
  readMoreLabel?: string;
  className?: string;
  initialHeight: number;
  fadeColour?: string;
  isGhosting?: boolean;
  containerRef?: any;
  user?: User;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const ReadMoreCollapse = styled(Collapse)({
  position: 'relative'
});

const Description: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginBottom: 0
}));

interface FadedProps extends ThemeProps {
  fade: boolean;
  fadeColour?: string;
}
const Faded: any = styled.div(({ fade, fadeColour, theme }: FadedProps) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  background: `linear-gradient(0, ${fadeColour || theme.custom.colors.white}, rgba(255,255,255,0))`,
  height: fade ? 50 : 0,
  transition: `height ${theme.custom.transitions.default}`,
  [mq.ie(breakpoints.small)]: {
    background: 'url(/assets/images/description-gradient.png) bottom left repeat'
  }
}));

/* ****************************** *
 *    TextDescriptionFadeComponent    *
 * ****************************** */

export const TextDescriptionFadeComponent = ({
  testId,
  description,
  readMoreLabel,
  className,
  initialHeight,
  fadeColour,
  isGhosting,
  containerRef,
  user
}: TextDestinationFadeProps) => {
  const t: any = useI18NextContext();
  const ref: any = useRef();
  const { width } = useWindowSize();
  const [showReadMore, setShowReadMore] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (ref && ref.current && ref.current.clientHeight > 0 && ref.current.clientHeight < initialHeight) {
      setExpanded(true);
      setShowReadMore(false);
    } else {
      setShowReadMore(true);
    }

    return () => {
      setExpanded(false);
      setShowReadMore(false);
    };
  }, [ref, width, description]);

  if (isGhosting) {
    return <SkeletonElement repeat={5} />;
  }

  const getReadMoreLabel = () => {
    if (readMoreLabel) {
      return typeof readMoreLabel === 'function' ? readMoreLabel : t(readMoreLabel);
    }
    return t(expanded ? 'common__description--read-less' : 'common__description--read-more');
  };

  const hasUser: boolean = !!user?.username && !!user?.locationName;

  return (
    <Container data-testid={testId || TestId.description.main} className={className} ref={containerRef}>
      <ReadMoreCollapse in={showReadMore ? expanded : true} collapsedSize={`${showReadMore ? initialHeight : 0}px`}>
        <Description data-testid={TestId.productDescription.description} ref={ref}>
          <Markdown plugins={[gfm as any]} children={description} components={LinkComponent} />
        </Description>
        {user && hasUser && <UserComponent username={user.username} locationName={user.locationName || ''} />}
        <Faded fade={!expanded && showReadMore} fadeColour={fadeColour} />
      </ReadMoreCollapse>
      {showReadMore && (
        <ReadMore data-testid={TestId.description.readMoreToggle} onClick={() => setExpanded(!expanded)}>
          <span>{getReadMoreLabel()}</span>
          <Icon name={expanded ? 'mhi-chevron-up' : 'mhi-chevron-down'} />
        </ReadMore>
      )}
    </Container>
  );
};

TextDescriptionFadeComponent.defaultProps = {
  initialHeight: 100
};

export const TextDescriptionFade = withTheme(TextDescriptionFadeComponent);
